<template>
    <div>
      <TopNav />
      <LearnerSideMenu />
      <main class="p-6 md:ml-64 h-auto pt-20">
        <div class="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
          <div class="flex items-center justify-between mt-4">
            <h1 class="text-4xl font-bold text-gray-800">Profile Settings</h1>
          </div>
          <p class="mt-2 text-gray-500">Manage your account details below.</p>
  
          <!-- Tab Navigation -->
          <div class="flex border-b border-gray-300 mt-8">
            <button
              v-for="tab in tabs"
              :key="tab.id"
              @click="activeTab = tab.id"
              :class="[ 
                'px-6 py-3 text-sm font-medium border-b-2',
                activeTab === tab.id
                  ? 'text-blue-700 border-blue-700'
                  : 'text-gray-600 border-transparent hover:text-blue-700 hover:border-blue-700'
              ]"
            >
              {{ tab.label }}
            </button>
          </div>
  
          <!-- Notification Area -->
          <div v-if="tabMessages[activeTab]?.success || tabMessages[activeTab]?.error" class="mt-6">
            <div v-if="tabMessages[activeTab]?.success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
              {{ tabMessages[activeTab].success }}
            </div>
            <div v-if="tabMessages[activeTab]?.error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded">
              <ul>
                <li v-for="message in tabMessages[activeTab].error" :key="message">{{ message }}</li>
              </ul>
            </div>
          </div>
  
          <!-- Tab Content -->
          <div class="mt-10">
            <!-- Account Tab -->
            <div v-if="activeTab === 'account'">
              <h2 class="text-2xl font-semibold text-gray-800 mb-6">Account Details</h2>
              <form @submit.prevent="updateProfile" class="space-y-6">
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label for="first_name" class="block text-sm font-semibold text-gray-700 mb-2">First Name</label>
                    <input
                      v-model="user.first_name"
                      type="text"
                      id="first_name"
                      class="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring focus:ring-blue-300 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label for="last_name" class="block text-sm font-semibold text-gray-700 mb-2">Last Name</label>
                    <input
                      v-model="user.last_name"
                      type="text"
                      id="last_name"
                      class="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring focus:ring-blue-300 focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label for="email" class="block text-sm font-semibold text-gray-700 mb-2">Email Address</label>
                  <input
                    v-model="user.email"
                    type="email"
                    id="email"
                    class="w-full border border-gray-300 rounded-lg px-4 py-3 bg-gray-100 cursor-not-allowed"
                    disabled
                  />
                </div>
                <div class="text-right">
                  <button
                    type="submit"
                    class="inline-flex items-center px-6 py-2 rounded-lg !bg-primary-700 text-white font-semibold hover:!bg-secondary-600 focus:outline-none"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
  
            <!-- Security Tab -->
            <div v-if="activeTab === 'security'">
              <h2 class="text-2xl font-semibold text-gray-800 mb-6">Change Password</h2>
              <form @submit.prevent="changePassword" class="space-y-6">
                <div>
                  <label for="current_password" class="block text-sm font-semibold text-gray-700 mb-2">Current Password</label>
                  <input
                    v-model="passwords.current"
                    type="password"
                    id="current_password"
                    class="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring focus:ring-blue-300 focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <label for="new_password" class="block text-sm font-semibold text-gray-700 mb-2">New Password</label>
                  <input
                    v-model="passwords.new"
                    type="password"
                    id="new_password"
                    class="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring focus:ring-blue-300 focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <label for="confirm_password" class="block text-sm font-semibold text-gray-700 mb-2">Confirm Password</label>
                  <input
                    v-model="passwords.confirm"
                    type="password"
                    id="confirm_password"
                    class="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring focus:ring-blue-300 focus:border-blue-500"
                    required
                  />
                </div>
                <div class="text-right">
                  <button
                    type="submit"
                    class="inline-flex items-center px-6 py-2 rounded-lg !bg-primary-700 text-white font-semibold hover:!bg-secondary-600 focus:outline-none"
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  

  
  <script>
  import TopNav from '@/components/headers/TopNav.vue';
  import LearnerSideMenu from '@/components/LearnerSideMenu.vue';
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      TopNav,
      LearnerSideMenu,
    },
  data() {
    return {
      tabs: [
        { id: 'account', label: 'Account' },
        { id: 'security', label: 'Security' },
      ],
      activeTab: 'account', // Default active tab
      user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      passwords: {
        current: '',
        new: '',
        confirm: '',
      },
      tabMessages: {
        account: { success: '', error: null },
        notifications: { success: '', error: null },
        billing: { success: '', error: null },
        security: { success: '', error: null },
      },
    };
  },
  methods: {
    async updateProfile() {
  const currentTab = this.tabMessages.account;
  currentTab.success = '';
  currentTab.error = null;

  try {
    // Send first_name, last_name, and email
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
      mutation UpdateUserDetails($input: UserInput!) {
        updateUserDetails(input: $input) {
          first_name
          last_name
          email
        }
      }
    `,
      variables: {
        input: {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email, // Include the required email field
        },
      },
    });

    if (response.data.errors) {
      const validationErrors = response.data.errors[0].extensions.validation;
      currentTab.error = validationErrors
        ? Object.values(validationErrors).flat()
        : [response.data.errors[0].message];
      return;
    }

    const updatedUser = response.data.data.updateUserDetails;
    localStorage.setItem('user', JSON.stringify(updatedUser));
    currentTab.success = 'Profile updated successfully!';
  } catch (error) {
    currentTab.error = ['An unexpected error occurred. Please try again.'];
  }
},
    async changePassword() {
      const currentTab = this.tabMessages.security;
      currentTab.success = '';
      currentTab.error = null;

      if (this.passwords.new !== this.passwords.confirm) {
        currentTab.error = ['New password and confirmation do not match.'];
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
          mutation UpdatePassword($current_password: String!, $new_password: String!, $new_password_confirmation: String!) {
            updatePassword(current_password: $current_password, new_password: $new_password, new_password_confirmation: $new_password_confirmation)
          }
        `,
          variables: {
            current_password: this.passwords.current,
            new_password: this.passwords.new,
            new_password_confirmation: this.passwords.confirm,
          },
        });

        if (response.data.errors) {
          const validationErrors = JSON.parse(response.data.errors[0].message);
          currentTab.error = Object.values(validationErrors).flat();
          return;
        }

        currentTab.success = 'Password updated successfully!';
        this.passwords = { current: '', new: '', confirm: '' };
      } catch (error) {
        currentTab.error = ['An unexpected error occurred. Please try again.'];
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  created() {
    const user = this.getUser;
    if (user) {
      this.user = { ...this.user, ...user };
    }
  },
};

  </script>
  