<template>
  <div
    class="p-6 bg-secondary-700 rounded-md shadow-lg hover:shadow-xl transition-all transform hover:scale-105 flex flex-col items-center justify-between space-y-4"
  >
    <!-- Icon Container -->
    

    <!-- Text Content -->
    <div class="text-center">
      <p class="text-sm font-semibold text-white dark:text-gray-300">
        {{ title }}
      </p>
      <h4
        class="text-3xl font-extrabold text-white dark:text-gray-100 tracking-tight"
      >
        {{ value }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add additional custom styles if needed */
</style>
