<template>
  <div class="audio-page-container">
    <h2 class="text-2xl font-bold mb-4 text-center">{{ unitData.title }}</h2>
    <p class="mt-8 mb-20 text-lg" v-html="unitData.content" v-if="unitData.content"></p>

    <!-- Audio Player -->
    <div v-if="fullAudioPath" class="audio-container mt-4">
      <audio controls class="w-full audio-player">
        <source :src="fullAudioPath" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>


    
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    fullAudioPath() {
      if (this.unitData.audio_path) {
        if (!this.unitData.audio_path.startsWith('http://') && !this.unitData.audio_path.startsWith('https://')) {
          return process.env.VUE_APP_API_URL + `${this.unitData.audio_path}`;
        }
        return this.unitData.audio_path;
      }
      return null;
    },
  },

};
</script>

<style scoped>
.audio-page-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.audio-container {
  width: 100%;
  margin-top: 16px;
  border-radius: 10px;
  background-color: #1a202c; /* Dark background */
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.audio-player {
  width: 100%;
  height: 50px;
  border-radius: 8px;
}

textarea {
  resize: none;
  border: 1px solid #d1d5db; /* Gray border */
  background-color: #ffffff; /* White background */
  transition: border-color 0.3s;
}

textarea:focus {
  border-color: #2563eb; /* Blue border on focus */
  outline: none;
}

button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  background-color: #2563eb; /* Blue background */
  color: #ffffff; /* White text */
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #1e3a8a; /* Darker blue on hover */
}
</style>
