import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Import your Vuex store
import LoginComponent from '../components/LoginComponent.vue';
import RegisterComponent from '../components/RegisterComponent.vue';
import LogoutButton from '../components/LogoutButton.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import LearnerDashboard from '../components/learner/LearnerDashboard.vue';
import CoursesTable from '../components/CoursesTable.vue';
import AddCourse from '../components/AddCourse.vue';
import EditCourse from '@/components/EditCourse.vue';
import MyCourses from '../components/learner/MyCourses.vue';
import MyProgress from '../components/learner/MyProgress.vue';
import MyBadges from '../components/learner/MyBadges.vue';
import CourseCatalog from '../components/learner/CourseCatalog.vue';
import ViewCourse from '../components/learner/ViewCourse.vue'; 
import CoursePage from '../components/learner/CoursePage.vue'; 
import AdminUsers from '../components/Admin/AdminUsers.vue'; 
import CreateUser from '../components/Admin/CreateUser.vue'; 
import UserGroups from '../components/Admin/UserGroups.vue'; 
import CreateGroup from '@/components/Admin/CreateGroup.vue'; 
import ViewGroup from '@/components/Admin/ViewGroup.vue';
import EditGroup from '@/components/Admin/EditGroup.vue';
import EditUser from '@/components/Admin/EditUser.vue';
import ViewUser from '@/components/Admin/ViewUser.vue';
import ViewBranch from '@/components/Admin/ViewBranch.vue';
import AdminBranches from '@/components/Admin/AdminBranches.vue';
import CreateBranch from '@/components/Admin/CreateBranch.vue';
import EditBranch from '@/components/Admin/EditBranch.vue';
import UserCertificates from '@/components/learner/UserCertificates.vue';
import MyProfile from '@/components/MyProfile.vue';
import AllUsersReports from '@/components/Reports/AllUsersReports.vue';
import UserReport from '@/components/Reports/UserReport.vue';
import AllCoursesReports from '@/components/Reports/AllCoursesReports.vue';
import CoursesReport from '@/components/Reports/CoursesReport.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'manager'] }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesTable,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/addcourse',
    name: 'Add Courses',
    component: AddCourse,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/courses/:id/edit',
    name: 'EditCourse',
    component: EditCourse,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/adduser',
    name: 'CreateUser',
    component: CreateUser,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/edituser/:id', // :id to capture user ID
    name: 'EditUser',
    component: EditUser,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
    },  
    {
      path: '/user/:id', // :id to capture user ID
      name: 'ViewUser',
      component: ViewUser,
      meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
      },  
  {
    path: '/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/groups',
    name: 'UserGroups',
    component: UserGroups,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/groups/create',
    name: 'CreateGroup',
    component: CreateGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/groups/:id',
    name: 'ViewGroup',
    component: ViewGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/groups/:id/edit',
    name: 'EditGroup',
    component: EditGroup,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor'] }
  },
  {
    path: '/branches',
    name: 'AdminBranches',
    component: AdminBranches,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin'] }
  },
  {
    path: '/branches/create',
    name: 'CreateBranch',
    component: CreateBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin'] }
  },
  {
    path: '/branches/:id',
    name: 'ViewBranch',
    component: ViewBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin'] }
  },
  {
    path: '/branches/:id/edit',
    name: 'EditBranch',
    component: EditBranch,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin'] }
  },
  {
    path: '/reports/users',
    name: 'AllUsersReports',
    component: AllUsersReports,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'manager'] }
  },
  {
    path: '/reports/users/:id',
    name: 'UserReport',
    component: UserReport,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'manager'] }
  },
  {
    path: '/reports/courses',
    name: 'AllCoursesReports',
    component: AllCoursesReports,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'manager'] }
  },
  {
    path: '/reports/courses/:id',
    name: 'CoursesReport',
    component: CoursesReport,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'manager'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutButton,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    meta: { requiresAuth: false }
  },
  // Learner-specific routes
  {
    path: '/learner/dashboard',
    name: 'Learner Dashboard',
    component: LearnerDashboard,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/learner/courses',
    name: 'My Courses',
    component: MyCourses,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/learner/progress',
    name: 'My Progress',
    component: MyProgress,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/learner/badges',
    name: 'My Badges',
    component: MyBadges,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/learner/course-catalog',
    name: 'Course Catalog',
    component: CourseCatalog,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/learner/catalog/course/:id', // Dynamic route for viewing a specific course
    name: 'ViewCourse',
    component: ViewCourse,
    props: true, // Allows route parameters to be passed as props to the component
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] } // Ensure the user is authenticated and is a learner
  },
  {
    path: '/learner/course/:course/unit/:unit_order?',
    name: 'course',
    component: CoursePage,
    props: true, 
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] } 
  },
  {
    path: '/learner/certificates',
    name: 'User Certificates',
    component: UserCertificates,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
  {
    path: '/profile',
    name: 'User Profile',
    component: MyProfile,
    meta: { requiresAuth: true, roles: ['super_admin', 'admin', 'instructor', 'learner', 'manager'] }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use createWebHistory for Vue 3
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowedRoles = to.meta.roles;

  // Detect subdomain and fetch branch data if not yet in the store
  const host = window.location.host;
  let branchUrl = null;
  if (host.includes('localhost')) {
    const subdomainMatch = host.match(/^(.+?)\.localhost/);
    branchUrl = subdomainMatch ? subdomainMatch[1] : null;
  } else if (host.includes('jamlms')) {
    const subdomainMatch = host.match(/^(.+?)\.jamlms/);
    branchUrl = subdomainMatch ? subdomainMatch[1] : null;
  } else {
    const parts = host.split('.');
    branchUrl = parts.length > 2 ? parts[0] : null;
  }

  if (branchUrl && !store.state.branch) {
    await store.dispatch('fetchBranch', branchUrl); // Fetch branch data based on subdomain
  }

  if (to.path === '/login' && token) {
    try {
      if (!store.getters.isAuthenticated) {
        await store.dispatch('fetchUser'); // Ensure user info is fetched
      }

      const userRole = store.getters.getUserRole;

      // Redirect to the appropriate dashboard based on role
      if (userRole === 'learner') {
        return next('/learner/dashboard');
      } else if (['super_admin', 'admin', 'instructor', 'manager'].includes(userRole)) {
        return next('/dashboard');
      }
    } catch (error) {
      console.error('Error during redirection:', error);
      localStorage.removeItem('token'); // Clear token if something goes wrong
      return next('/login');
    }
  }

  // If the route requires authentication
  if (requiresAuth) {
    if (!token) {
      localStorage.setItem('intendedRoute', to.fullPath);
      return next('/login');
    }

    try {
      if (!store.getters.isAuthenticated) {
        await store.dispatch('fetchUser'); // Fetch user info if not already fetched
      }

      

      const userRole = store.getters.getUserRole;

      // Handle redirection to role-specific dashboards
      if (to.path === '/dashboard' || to.path === '/') {
        if (userRole === 'learner') {
          return next('/learner/dashboard');
        }
      }
      if (allowedRoles && !allowedRoles.includes(userRole)) {
        return next('/login');
      }

      next(); // Allow navigation to the intended route
    } catch (error) {
      localStorage.removeItem('token'); // Clear token if error occurs
      return next('/login');
    }
  } else {
    // Allow navigation for public routes
    next();
  }
});


export default router;
