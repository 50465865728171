<template>
  <div
    v-if="visible"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    @click.self="closeModal"
  >
    <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-4xl relative">
      <!-- Header -->
      <header class="flex justify-between items-center px-6 py-4 bg-primary-700 text-white rounded-t-lg">
        <h2 class="text-lg font-semibold truncate" :title="courseTitle">
          Course: {{ truncatedCourseTitle }}
        </h2>
        <button
          @click="closeModal"
          class="text-white hover:bg-secondary-600 p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400"
          aria-label="Close"
        >
        <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
        
        </button>
      </header>

      <!-- Content -->
      <div class="p-6 max-h-[70vh] overflow-y-auto custom-scrollbar">
        <div v-if="Object.keys(filteredUnits).length > 0">
          <div
            v-for="(units, typeKey) in filteredUnits"
            :key="typeKey"
            class="mb-6"
          >
            <!-- Unit Group Header -->
            <h3 class="font-semibold text-lg text-gray-800 dark:text-gray-300 capitalize mb-4">
              {{ typeKey.replace('_', ' ') }}
            </h3>

            <!-- Table -->
            <div class="overflow-x-auto">
              <table class="w-full table-auto border-collapse bg-gray-50 dark:bg-gray-900 rounded-lg shadow">
                <thead class="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th class="px-4 py-2 text-left text-sm font-semibold text-gray-600 dark:text-gray-300 w-2/3">
                      Title
                    </th>
                    <th class="px-4 py-2 text-center text-sm font-semibold text-gray-600 dark:text-gray-300 w-1/6">
                      Completed
                    </th>
                    <th class="px-4 py-2 text-center text-sm font-semibold text-gray-600 dark:text-gray-300 w-1/6">
                      Time Spent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="unit in units"
                    :key="unit.id"
                    class="border-b border-gray-200 dark:border-gray-700 last:border-0 hover:bg-gray-100 dark:hover:bg-gray-800"
                  >
                    <!-- Truncated Title -->
                    <td
                      class="px-4 py-2 text-gray-700 dark:text-gray-300 truncate"
                      :title="unit.title"
                    >
                      {{ truncateText(unit.title, 50) }}
                    </td>
                    <!-- Completion Status -->
                    <td class="px-4 py-2 text-center">
                      <span
                        class="px-2 py-1 rounded-full text-xs font-medium"
                        :class="unit.is_complete ? 'bg-green-100 text-green-600 dark:bg-green-700 dark:text-green-200' : 'bg-red-100 text-red-600 dark:bg-red-700 dark:text-red-200'"
                      >
                        {{ unit.is_complete ? 'Yes' : 'No' }}
                      </span>
                    </td>
                    <!-- Time Spent -->
                    <td class="px-4 py-2 text-center text-gray-700 dark:text-gray-300">
                      {{ formatTime(unit.time_spent) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- No Units Message -->
        <div v-else class="text-center py-10 text-gray-600 dark:text-gray-300">
          <p>No units found for this course.</p>
        </div>
      </div>

      <!-- Footer -->
      <!-- <footer class="px-6 py-3 bg-gray-100 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
        <button
          @click="closeModal"
          class="px-4 py-2 bg-primary-700 text-white rounded-lg shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400"
        >
          Close
        </button>
      </footer> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    units: {
      type: Object,
      default: () => ({
        contents: [],
        videos: [],
        audios: [],
        documents: [],
        iframes: [],
        scorms: [],
        assignments: [],
        tests: [],
      }),
    },
    courseTitle: {
      type: String,
      default: 'Unknown Course',
    },
  },
  computed: {
    filteredUnits() {
      return Object.entries(this.units)
        .filter(([, unitList]) => unitList.length > 0)
        .reduce((filtered, [typeKey, unitList]) => {
          filtered[typeKey] = unitList;
          return filtered;
        }, {});
    },
    truncatedCourseTitle() {
      const maxLength = 50;
      return this.courseTitle.length > maxLength
        ? this.courseTitle.slice(0, maxLength) + "..."
        : this.courseTitle || "Unknown Course";
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (!text) return 'Test';
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
      return `${hrs}h ${mins}m ${secs}s`;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d1d5db; /* Tailwind Gray-300 */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #9ca3af; /* Tailwind Gray-500 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}
</style>
