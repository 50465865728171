<template>
  <div class="flex justify-center">
    <div class="w-full max-w-7xl mx-4 mt-8">
      <div class="p-8 mb-8">
        <h1 class="text-4xl mb-6 font-semibold text-gray-800">Content Unit</h1>
        <input
          v-model="localUnitTitle"
          type="text"
          placeholder="Add a title for this unit"
          class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mb-4"
          @input="emitData"
        />
            <div id="quill-editor" class="quill-editor"></div>

        <div class="flex items-center justify-between mb-4">
          <h3 class="text-lg font-semibold">Complete unit</h3>
          <select
            v-model="localcompletion_option"
            class="border border-gray-300 focus:outline-none px-4 py-2"
            @change="emitData"
          >
            <option value="">Select completion option</option>
            <option value="time">After a period of time</option>
            <option value="question">With a question</option>
          </select>
        </div>

        <div v-if="localcompletion_option === 'time'" class="mb-4">
          <input
            v-model="localTimeLimit"
            type="number"
            placeholder="Seconds"
            class="w-32 border border-gray-300 focus:outline-none px-4 py-2 mr-2"
            @input="emitData"
          />
        </div>

        <div v-else-if="localcompletion_option === 'question'" class="mb-4">
          <TestButton @add-question="openQuestionDialog" />

          <div
            v-for="(question, index) in localQuestions"
            :key="index"
            class="w-full bg-white shadow-md rounded-lg p-4 mb-4"
          >
            <div class="flex justify-between items-center">
              <div>
                <h3 class="text-lg font-semibold">Question {{ index + 1 }}: {{ question.type }}</h3>
                <p>{{ question.question }}</p>
              </div>
              <div class="flex items-center space-x-2">
                <button @click="toggleQuestionDetails(index)">
                  <svg
                    v-if="!question.showDetails"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 15l-7-7-7 7" />
                  </svg>
                </button>
                <button @click="deleteQuestion(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500 hover:text-red-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            <div v-if="question.showDetails" class="mt-4">
              <div v-if="question.options" class="mb-2">
                <h4 class="text-md font-semibold">Options:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(option, idx) in question.options" :key="idx">
                    {{ option.text }} <span v-if="option.checked">(Correct)</span>
                  </li>
                </ul>
              </div>
              <div v-if="question.pairs" class="mb-2">
                <h4 class="text-md font-semibold">Pairs:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(pair, idx) in question.pairs" :key="idx">
                    {{ pair.left }} = {{ pair.right }}
                  </li>
                </ul>
              </div>
              <div v-if="question.fillTheGaps" class="mb-2">
                <h4 class="text-md font-semibold">Fill the Gaps:</h4>
                <p>{{ question.fillTheGaps }}</p>
              </div>
              <div v-if="question.rules" class="mb-2">
                <h4 class="text-md font-semibold">Rules:</h4>
                <ul class="list-disc list-inside">
                  <li v-for="(rule, idx) in question.rules" :key="idx">
                    When it {{ rule.condition }} the word "{{ rule.word }}", add {{ rule.points }} points.
                  </li>
                </ul>
                <p>Required Points: {{ question.requiredPoints }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TestDialog
    v-if="isDialogOpen"
    :isOpen="isDialogOpen"
    :type="questionType"
    @close="isDialogOpen = false"
    @save-question="saveQuestion"
  />
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import TestButton from '@/components/AddCourse/TestButton.vue';
import TestDialog from '@/components/AddCourse/TestDialog.vue';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
  components: {
    TestButton,
    TestDialog,
  },
  props: {
    unitData: {
      type: Object,
      required: true,
    },
  },
  emits: ['data-updated'],
  setup(props, { emit }) {
    const localUnitTitle = ref('');
    const localUnitContent = ref({});
    const localcompletion_option = ref('');
    const localTimeLimit = ref(null);
    const localQuestions = ref([]);
    const isDialogOpen = ref(false);
    const questionType = ref('');
    let editor = null;
    let isUpdating = false;

    const initializeEditor = () => {
  const quillContainer = document.getElementById('quill-editor');
  if (quillContainer) {
    editor = new Quill(quillContainer, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Header options
          [{ font: [] }], // Font family options
          ['bold', 'italic', 'underline', 'strike'], // Formatting options
          [{ list: 'ordered' }, { list: 'bullet' }], // List options
          [{ align: [] }], // Alignment options
          ['link', 'image'], // Insert options
          ['clean'], // Clear formatting
        ],
      },
      placeholder: 'Compose an epic...',
    });

    let parsedContent = {};

    if (typeof props.unitData.content === 'string') {
      try {
        parsedContent = JSON.parse(props.unitData.content || '{}');
      } catch (error) {
        console.error('Error parsing content string:', error);
        parsedContent = { ops: [] };
      }
    } else if (typeof props.unitData.content === 'object') {
      parsedContent = props.unitData.content;
    } else {
      parsedContent = { ops: [] };
    }

    if (parsedContent.ops) {
      // Prepend API URL to image sources
      prependApiUrlToImages(parsedContent);
      // Set content silently to prevent triggering text-change event
      editor.setContents(parsedContent, 'silent');
      localUnitContent.value = parsedContent; // Ensure localUnitContent reflects the editor content
    }

    editor.on('text-change', () => {
  if (!isUpdating) {
    const currentContent = editor.getContents();
    prependApiUrlToImages(currentContent);
    localUnitContent.value = currentContent;
    emitData();
  }
});

  }
};

const prependApiUrlToImages = (content) => {
  if (content && content.ops) {
    content.ops.forEach((op) => {
      if (op.insert && op.insert.image) {
        const image = op.insert.image;
        // Add `VUE_APP_API_URL` if the image is a relative path
        if (typeof image === 'string' && !image.startsWith('http') && !image.startsWith('data:image')) {
          op.insert.image = `${process.env.VUE_APP_API_URL}/${image.replace(/^\/+/, '')}`;
        }
      }
    });
  }
};


    const emitData = () => {
      emit('data-updated', {
        title: localUnitTitle.value,
        content: localUnitContent.value,
        completion_option: localcompletion_option.value,
        timeLimit: localTimeLimit.value,
        questions: localQuestions.value,
      });
    };

    const initializeData = () => {
      localUnitTitle.value = props.unitData.title || '';
      localUnitContent.value = props.unitData.content || { ops: [] };
      localcompletion_option.value = props.unitData.completion_option || '';
      localTimeLimit.value = props.unitData.timeLimit || null;
      localQuestions.value = props.unitData.questions || [];
    };

    watch(
  () => props.unitData,
  (newData) => {
    if (newData) {
      initializeData();
      if (editor) {
        // Parse the new content
        let parsedContent = {};
        if (typeof newData.content === 'string') {
          try {
            parsedContent = JSON.parse(newData.content || '{}');
          } catch (error) {
            console.error('Error parsing content string:', error);
            parsedContent = { ops: [] };
          }
        } else if (typeof newData.content === 'object') {
          parsedContent = newData.content;
        } else {
          parsedContent = { ops: [] };
        }

        // Compare new content with current editor content
        if (JSON.stringify(parsedContent) !== JSON.stringify(editor.getContents())) {
          isUpdating = true; // Start programmatic update
          if (!newData.content || Object.keys(newData.content).length === 0) {
            editor.setContents({ ops: [] }, 'silent'); // Clear Quill content
            localUnitContent.value = {}; // Reset local content
          } else {
            prependApiUrlToImages(parsedContent);
            editor.setContents(parsedContent, 'silent'); // Set existing content
            localUnitContent.value = parsedContent;
          }
          isUpdating = false; // End programmatic update
        }
      }
    }
  },
  { immediate: true, deep: true }
);



    const openQuestionDialog = (type) => {
      questionType.value = type;
      isDialogOpen.value = true;
    };

    const saveQuestion = (questionData) => {
      localQuestions.value.push({ ...questionData, showDetails: false });
      emitData();
    };

    const deleteQuestion = (index) => {
      localQuestions.value.splice(index, 1);
      emitData();
    };

    const toggleQuestionDetails = (index) => {
      localQuestions.value[index].showDetails = !localQuestions.value[index].showDetails;
    };

    onMounted(() => {
      initializeData();
      initializeEditor();
    });

    return {
      localUnitTitle,
      localUnitContent,
      localcompletion_option,
      localTimeLimit,
      localQuestions,
      isDialogOpen,
      questionType,
      emitData,
      openQuestionDialog,
      saveQuestion,
      deleteQuestion,
      toggleQuestionDetails,
    };
  },
};
</script>

<style scoped>
.quill-editor {
  min-height: 300px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
</style>
