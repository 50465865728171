<template>
  <div class="document-unit">
    <h2 class="text-2xl font-bold mb-4 text-center">{{ unitData.title }}</h2>
    <p class="mt-10 mb-10 text-lg" v-html="unitData.description" v-if="unitData.description"></p>

    <div v-if="documentUrl" class="document-container mt-4">
      <p>This document type cannot be displayed directly. Please download it using the link below:</p>
      <a :href="documentUrl" :download="getFileName(unitData.file_path)" class="text-blue-600 hover:underline">
        Download {{ unitData.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentUrl: null,
    };
  },
  created() {
    this.initializeDocumentUrl();
  },
  methods: {
    initializeDocumentUrl() {
      // Directly assign the file_path as the download URL
      if (this.unitData.file_path) {
        this.documentUrl = this.unitData.file_path;
      } else {
        console.error("No valid file path found.");
      }
    },
    getFileName(filePath) {
      // Extract the file name from the file path
      return filePath.substring(filePath.lastIndexOf('/') + 1);
    },
  },
};
</script>
