<template>
  <div>
    <TopNav />
    <SideMenu />
    <main class="p-6 md:ml-64 h-auto pt-20 bg-gray-50">
      <div class="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
        <!-- Header -->
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 bg-white shadow-md rounded-lg p-6">
          <div>
            <h1 class="text-4xl font-bold text-gray-800 mb-2">Course Reports</h1>
            <p class="text-gray-500 text-sm">
              Track and manage course activity across the platform 
            </p>
          </div>
          <!-- <button
            class="text-white px-6 py-2 rounded-lg shadow-lg focus:outline-none bg-secondary-700"
            @click="exportReport"
          >
            Export Report
          </button> -->
        </div>

        <!-- Statistics Section -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatCard
          title="Total Courses"
          :value="totalCourses"
          icon="completion-rate"
        />
          <StatCard
            title="Completion Rate"
            :value="stats.completionRate + '%'"
            icon="completion-rate"
          />
          <StatCard
            title="Completed"
            :value="stats.completedLearners"
            icon="completed-learners"
          />
          <StatCard
            title="In Progress"
            :value="stats.learnersInProgress"
            icon="in-progress"
          />
          <StatCard
            title="Not Started"
            :value="stats.learnersNotStarted"
            icon="not-started"
          />
          <StatCard
            title="Total Training Time"
            :value="stats.trainingTime"
            icon="training-time"
          />
        </div>

        <!-- Search and Table Section -->
        <div>
          <!-- Search Bar -->
          <div class="flex items-center justify-between mb-4">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search courses by title or category..."
              class="w-full sm:w-1/3 px-4 py-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300 focus:border-blue-500 text-gray-800"
            />
            <button
              @click="resetSearch"
              v-if="searchQuery"
              class="text-sm text-gray-500 underline hover:text-gray-700 ml-4"
            >
              Clear Search
            </button>
          </div>

          <!-- Courses Table -->
          <div v-if="loading" class="text-center py-10">
            <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
                    <div v-else class="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
            <table class="min-w-full divide-y divide-gray-200 bg-white">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    class="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    class="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Category
                  </th>
                  <th
                    class="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Assigned Learners
                  </th>
                  <th
                    class="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Completed Learners
                  </th>
                  <th
                    class="px-6 py-4 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="course in filteredCourses"
                  :key="course.id"
                  class="hover:bg-blue-50 cursor-pointer transition"
                  @click="viewCourseDetails(course.id)"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                    {{ course.title || 'N/A' }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {{ course.category || 'N/A' }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-sm font-semibold text-gray-800"
                  >
                    {{ course.assignedLearners || 0 }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-sm font-semibold text-green-600"
                  >
                    {{ course.completedLearners || 0 }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <button
                      class="text-primary-700 hover:text-blue-800"
                      @click.stop="viewCourseDetails(course.id)"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
                <tr v-if="!filteredCourses.length">
                  <td colspan="5" class="text-center py-8 text-gray-500 text-sm">
                    No courses found. Try refining your search.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';
import StatCard from '@/components/Reports/StatCard.vue';
import axios from 'axios';

export default {
  components: {
    TopNav,
    SideMenu,
    StatCard,
  },
  data() {
    return {
      stats: {
        completionRate: 0,
        completedLearners: 0,
        learnersInProgress: 0,
        learnersNotStarted: 0,
        trainingTime: '0h 0m',
      },
      totalCourses: 0,
      courses: [],
      searchQuery: '',
      loading: false,
    };
  },
  computed: {
    filteredCourses() {
      return this.courses.filter(course =>
        (course.title || '').toLowerCase().includes((this.searchQuery || '').toLowerCase()) ||
        (course.category || '').toLowerCase().includes((this.searchQuery || '').toLowerCase())
      );
    },
  },
  methods: {
    async fetchCourses() {
      this.loading = true;
      try {
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null; // Retrieve branchUrl if available
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
        query: `
          query ($branchUrl: String) {
            reportsAllCourses(branchUrl: $branchUrl) {
              totalCourses
              courses {
                id
                title
                category
                assignedLearners
                completedLearners
                learnersInProgress
                learnersNotStarted
                trainingTime
                progress
                date_assigned
              }
            }
          }
        `,
        variables: {
          branchUrl,
        },
      });
        const data = response.data?.data?.reportsAllCourses || {};
        this.totalCourses = data.totalCourses || 0;
        this.courses = (data.courses || []).map(course => ({
          ...course,
          title: course.title || 'N/A',
          category: course.category || 'N/A',
          assignedLearners: course.assignedLearners || 0,
          completedLearners: course.completedLearners || 0,
          learnersInProgress: course.learnersInProgress || 0,
          learnersNotStarted: course.learnersNotStarted || 0,
          trainingTime: course.trainingTime || '0h 0m 0s',
          progress: course.progress || 0,
        }));
        this.updateStats(this.courses);
      } catch (error) {
        console.error('Error fetching course data:', error);
      } finally {
        this.loading = false;
      }
    },
    updateStats(courses) {
      const completedLearners = courses.reduce((sum, course) => sum + (course.completedLearners || 0), 0);
      const learnersInProgress = courses.reduce((sum, course) => sum + (course.learnersInProgress || 0), 0);
      const learnersNotStarted = courses.reduce((sum, course) => sum + (course.learnersNotStarted || 0), 0);
      const totalLearners = completedLearners + learnersInProgress + learnersNotStarted;

      let totalTrainingSeconds = 0;
      courses.forEach(course => {
      const [hours, minutes, seconds] = (course.trainingTime || '0h 0m 0s')
        .replace(/h|m|s/g, '')
        .split(' ')
        .map(Number);
      totalTrainingSeconds += (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    });

    const totalTrainingHours = Math.floor(totalTrainingSeconds / 3600);
    const remainingMinutes = Math.floor((totalTrainingSeconds % 3600) / 60);
    const remainingSeconds = totalTrainingSeconds % 60;

      this.stats = {
        completionRate: totalLearners ? ((completedLearners / totalLearners) * 100).toFixed(2) : '0.0',
        completedLearners,
        learnersInProgress,
        learnersNotStarted,
        trainingTime: `${totalTrainingHours}h ${remainingMinutes}m ${remainingSeconds}s`,
      };
    },
    viewCourseDetails(courseId) {
      this.$router.push({ name: 'CoursesReport', params: { id: courseId } });
    },
    resetSearch() {
      this.searchQuery = '';
    },
    exportReport() {
      console.log('Export functionality to be implemented');
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>

<style>
/* Table Styling */
table th,
table td {
  text-align: left;
  padding: 0.75rem 1.5rem;
}

table th {
  background-color: #f9fafb;
  color: #4b5563;
  text-transform: uppercase;
}

table tr:nth-child(even) {
  background-color: #f8fafc;
}

table tr:hover {
  background-color: #ebf5ff;
}

/* Button Styling */
button {
  transition: color 0.2s, background-color 0.2s;
}
</style>
