<template>
  <div
    class="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
  >
    <img
      :src="courseThumbnail(course.thumbnail)"
      alt="Course Thumbnail"
      class="w-full h-48 object-cover"
    />
    <div class="relative w-full bg-gray-200 h-4" v-if="type != 'catalog'">
      <div
        class="bg-blue-600 h-4"
        :style="{ width: course.completionPercentage + '%' }"
      ></div>
      <div class="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
        {{ course.completionPercentage }}%
      </div>
    </div>
    <div class="p-4">
      <h3 class="text-lg font-semibold">{{ course.title || 'Untitled Course' }}</h3>
      <p class="text-sm text-gray-500" v-if="type != 'catalog'">
        Started: {{ course.enrolledDate ? formatStartDate(course.enrolledDate) : 'N/A' }}
      </p>
    </div>
    <div class="p-4">
      <router-link
        :to="{ name: 'ViewCourse', params: { id: course.id } }"
        class="w-full bg-primary-600 text-white font-semibold py-2 px-4 rounded-lg text-center block"
      >
        View Course
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "enrolled"
    }
  },
  methods: {
    formatStartDate(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    courseThumbnail() {
      if (!this.course.thumbnail) {
        return '/img/default-course-image.jpg'; // Fallback to default image
      }

      // Check if the thumbnail is already an absolute URL
      if (this.course.thumbnail.startsWith('http://') || this.course.thumbnail.startsWith('https://')) {
        return this.course.thumbnail;
      }

      // Prepend the base API URL for relative paths
      return `${process.env.VUE_APP_API_URL}${this.course.thumbnail.startsWith('/') ? '' : '/'}${this.course.thumbnail}`;
    },
  },
};
</script>
