<template>
  <div>
    <h2 class="text-3xl font-semibold mb-4">{{ unitData.title }}</h2>
    <!-- Render the converted HTML -->
    <div v-html="convertedHtml" class="quill-content"></div>
  </div>
</template>

<script>
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    courseId: {
      type: [String, Number],
      required: true,
    },
    unitId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    convertedHtml() {
      if (this.unitData.content) {
        try {
          // Parse the JSON string if it is not already an object
          const delta = typeof this.unitData.content === 'string' 
            ? JSON.parse(this.unitData.content) 
            : this.unitData.content;

          // Prepend the API URL to relative image paths
          this.prependApiUrlToImages(delta);

          // Convert the Quill Delta to HTML
          const converter = new QuillDeltaToHtmlConverter(delta.ops, {
            inlineStyles: true, // Use inline styles for formatting
          });

          return converter.convert(); // Returns the HTML string
        } catch (error) {
          console.error('Error converting Quill content to HTML:', error);
          return '<p>Error rendering content</p>';
        }
      }
      return '<p>No content available</p>';
    },
  },
  methods: {
    prependApiUrlToImages(content) {
      if (content && content.ops) {
        content.ops.forEach((op) => {
          if (op.insert && op.insert.image) {
            const image = op.insert.image;
            // Add `VUE_APP_API_URL` if the image is a relative path
            if (typeof image === 'string' && !image.startsWith('http') && !image.startsWith('data:image')) {
              op.insert.image = `${process.env.VUE_APP_API_URL}/${image.replace(/^\/+/, '')}`;
            }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.quill-content {
  line-height: 1.6;
}

/* Optional: Add Quill styles */
img {
  max-width: 100%;
  height: auto;
}
</style>
