<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav />
    <SideMenu />

    <main class="p-6 md:ml-64 h-auto pt-20">
      <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-3xl px-4 lg:px-12">
          <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
            <div class="flex items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <h2 class="text-2xl font-semibold text-gray-900 dark:text-gray-100">My Certificates</h2>
            </div>

            <div v-if="loading" class="text-center py-10">
              <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>

            <div v-if="!loading && completedCourses.length" class="overflow-x-auto">
              <table class="w-full text-md text-left text-gray-500 dark:text-gray-400 bg-white shadow-md mt-5">
                <thead class="text-md text-gray-700 bg-gray-200">
                  <tr>
                    <th class="px-4 py-3">Course Name</th>
                    <th class="px-4 py-3">Issued At</th>
                    <th class="px-4 py-3">Expires At</th>
                    <th class="px-4 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="course in completedCourses"
                    :key="course.id"
                    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td class="px-4 py-3">{{ course.title }}</td>
                    <td class="px-4 py-3">{{ course.completed_date || 'N/A' }}</td>
                    <td class="px-4 py-3">{{ calculateExpiryDate(course.completed_date) }}</td>
                    <td class="px-4 py-3">
                      <button
                        @click="downloadCertificate(course)"
                        class="p-2 rounded hover:bg-gray-200 dark:hover:bg-gray-700"
                      >
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z" clip-rule="evenodd"/>
                          <path fill-rule="evenodd" d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clip-rule="evenodd"/>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="!loading && !completedCourses.length" class="text-center text-gray-600 dark:text-gray-400 py-10">
              <h2 class="text-3xl font-semibold mb-4">No Completed Courses</h2>
              <p class="text-lg">You haven't completed any courses yet.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/LearnerSideMenu.vue';

export default {
  components: { TopNav, SideMenu },
  data() {
    return {
      completedCourses: [],
      loading: true,
    };
  },
  methods: {
    async fetchCompletedCourses() {
      this.loading = true;
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query fetchCompletedCourses($branchUrl: String) {
              CompletedCourses(branchUrl: $branchUrl) {
                id
                title
                is_complete
                completed_date
              }
            }
          `,
          variables: {
            branchUrl,
          },
        });
        this.completedCourses = response.data.data.CompletedCourses || [];
      } catch (error) {
        console.log('Error fetching completed courses:', error);
      } finally {
        this.loading = false;
      }
    },
    calculateExpiryDate(completedDate) {
      if (!completedDate) return 'N/A';
      const completed = new Date(completedDate.split('/').reverse().join('-'));
      completed.setFullYear(completed.getFullYear() + 1);
      return completed.toLocaleDateString('en-GB');
    },
    async downloadCertificate(course) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation GenerateCertificate($input: GenerateCertificateInput!) {
              GenerateCertificate(input: $input) {
                message
                certificate_url
              }
            }
          `,
          variables: {
            input: {
              user_name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
              course_name: course.title,
              issued_date: course.completed_date.split('/').reverse().join('-'),
              expiry_date: this.calculateExpiryDate(course.completed_date).split('/').reverse().join('-'),
            },
          },
        });

        const { certificate_url } = response.data.data.GenerateCertificate;

        const link = document.createElement('a');
        link.href = certificate_url;
        link.download = `${course.title}-certificate.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.log('Error downloading certificate:', error);
      }
    },
  },
  created() {
    this.fetchCompletedCourses();
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
