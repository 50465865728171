<template>
    <div class="flex justify-center">
        <!-- Main container -->
        <div class="w-full max-w-7xl mx-4 mt-8">
            <!-- Course Card -->
            <div class="p-8 mb-8">
                <h1 class="text-4xl mb-6 font-semibold text-gray-800">Assignment Unit</h1>

                <!-- Input box for unit title -->
                <input 
                    v-model="localUnitTitle" 
                    type="text" 
                    placeholder="Add a title for this unit"
                    class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mb-4"
                    @input="emitData" 
                />

                <!-- Large content box -->
                <textarea 
                    v-model="localUnitContent" 
                    placeholder="Enter unit content..."
                    class="w-full h-48 border-2 border-gray-300 focus:outline-none focus:border-blue-500 px-4 py-2 mb-4"
                    @input="emitData">
                </textarea>

                <!-- Section title: Complete unit -->
                <div class="flex items-center justify-between mb-4">
                    <h3 class="text-lg font-semibold">Complete unit</h3>
                    <!-- Dropdown for completion options -->
                    <select 
                        v-model="localcompletion_option" 
                        class="border border-gray-300 focus:outline-none px-4 py-2"
                        @change="emitData"
                    >
                        <option value="">Select completion option</option>
                        <option value="instructor-accepts">Instructor accepts the answer</option>
                        <option value="user-uploads">When uploading an answer</option>
                    </select>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            localUnitTitle: '',
            localUnitContent: '',
            localcompletion_option: '',
        };
    },
    props: {
        unitData: {
            type: Object,
            required: true,
        },
        unit_counter: {
            type: Number,
            required: true,
        },
    },
    watch: {
        unitData: {
            handler(newData) {
                this.localUnitTitle = newData.title || '';
                this.localUnitContent = newData.content || '';
                this.localcompletion_option = newData.completion_option || '';
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        emitData() {
            this.$emit('data-updated', {
                unit_order: this.unit_counter,
                title: this.localUnitTitle,
                content: this.localUnitContent,
                completion_option: this.localcompletion_option,
            });
        }
    }
};
</script>
