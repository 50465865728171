<template>
  <BasicHeader :branch="branch" />
  <section class="bg-gray-50 relative z-0">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <h1
        class="mb-7 text-3xl font-normal leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
        Log in to your account
      </h1>

      <div
        class="w-full bg-white rounded-md shadow-xl dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <form @submit.prevent="login" class="space-y-4 md:space-y-6">
            <div>
              <label for="email" class="block mb-2 text-lg font-medium capitalize text-gray-900 dark:text-white">Your
                email</label>
              <input v-model="email" type="email" name="email" id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full px-10 py-4 placeholder-gray-400"
                placeholder="Email address" required>
            </div>
            <div>
              <label for="password" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Password</label>
              <input v-model="password" type="password" name="password" id="password" placeholder="Password"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full py-4 placeholder-gray-400"
                required>
            </div>
            <div class="flex items-center justify-between">
              <!-- <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300">
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div> -->
              <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 ">Forgot
                password?</a>
            </div>
            <button
            type="submit"
            :disabled="loading"
            class="w-full min-h-[2.5rem] text-white !bg-primary-700 hover:!bg-blue-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-semibold rounded-md text-sm px-5 py-2.5 text-center flex items-center justify-center relative"
          >
            <span v-if="loading" class="absolute flex justify-center items-center uppercase">
              <!-- A spinner, e.g., a simple animated SVG -->
              <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            </span>
            <span v-else>
              LOGIN
            </span>
          </button>
            <p v-if="error" class="text-sm font-bold text-red-500">{{ error }}</p>
            <!-- <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet? <router-link to="/register"
                class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</router-link>
            </p> -->
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import BasicHeader from './headers/BasicHeader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
      loading: false
    };
  },
  components: {
    BasicHeader,
  },
  computed: {
    ...mapGetters(['getBranch']),
    branch() {
      return this.getBranch;
    },
  },
  methods: {
    ...mapActions(['fetchBranch']),
    async loadBranchData() {
      const host = window.location.host;
      const parts = host.split('.');
      const subdomain = (parts.length > 2 && host !== 'localhost' || parts.length > 2 && host !== 'jamlms') ? parts[0] : null;
      if (subdomain) {
        await this.fetchBranch(subdomain);
      }
    },
    async login() {
      this.loading = true; // Show spinner and disable button
      try {
        const host = window.location.host;
        let branchUrl = null;

        if (host.includes('localhost')) {
          const subdomainMatch = host.match(/^(.+?)\.localhost/);
          branchUrl = subdomainMatch ? subdomainMatch[1] : null;
        } else if (host.includes('jamlms')) {
          const subdomainMatch = host.match(/^(.+?)\.jamlms/);
          branchUrl = subdomainMatch ? subdomainMatch[1] : null;
        } else {
          const parts = host.split('.');
          branchUrl = parts.length > 2 ? parts[0] : null;
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
          mutation {
            loginUser(email: "${this.email}", password: "${this.password}"${branchUrl ? `, branch_url: "${branchUrl}"` : ''}) {
              user {
                email
              }
              token
            }
          }
          `
        });

        if (response.data.errors) {
      const error = response.data.errors[0];
      if (error.extensions && error.extensions.validation) {
        const validationErrors = error.extensions.validation;

        // Check for specific validation errors
        if (validationErrors.email) {
          this.error = validationErrors.email[0]; // Display email-specific error message
        } else if (validationErrors.branch_url) {
          this.error = validationErrors.branch_url[0]; // Display branch_url-specific error message
        } else {
          this.error = 'An error occurred. Please try again later.';
        }
      } else {
        this.error = 'An error occurred. Please try again later.';
      }
      return;
    }

        const { token, user } = response.data.data.loginUser;
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('user', JSON.stringify(user));
        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Login error:', error);
        this.error = 'An error occurred while logging in. Please try again later.';
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.loadBranchData();
  },
};
</script>
