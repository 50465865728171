<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
      <TopNav />
      <SideMenu />
  
      <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
        <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
          <div class="mb-6 flex justify-between items-center">
            <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Create New User</h2>
            <a href="/users" class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              <span class="ml-2">Back</span>
            </a>
          </div>
  
          <form @submit.prevent="createUser">
            <!-- Inline Error Messages -->
            <div v-if="errors.length" class="mb-4 bg-red-100 text-red-800 p-3 rounded">
              <ul>
                <li v-for="(error, index) in errors" :key="index" class="text-sm">{{ error }}</li>
              </ul>
            </div>
  
            <!-- First Name and Last Name Fields in Grid -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="mb-4">
                <label for="first_name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">First Name</label>
                <input type="text" id="first_name" v-model="form.first_name" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" required />
              </div>
  
              <div class="mb-4">
                <label for="last_name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Last Name</label>
                <input type="text" id="last_name" v-model="form.last_name" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" required />
              </div>
            </div>
  
            <!-- Email Field -->
            <div class="mb-4">
              <label for="email" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Email</label>
              <input type="email" id="email" v-model="form.email" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" required />
            </div>
  
            <!-- Password Field -->
            <div class="mb-4">
              <label for="password" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Password</label>
              <input type="password" id="password" v-model="form.password" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" required />
              <small class="text-xs text-gray-500 dark:text-gray-400">Password should be at least 8 characters.</small>
            </div>
  
            <!-- User Type Dropdown -->
            <div class="mb-4">
              <label for="role" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">User Type</label>
              <select id="role" v-model="form.role" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" required>
                <option value="" disabled>Select user type</option>
                <option value="admin">Admin</option>
                <option value="learner">Learner</option>
                <option value="manager">Manager</option>
                <option value="instructor">Instructor</option>
              </select>
            </div>
            
            <!-- Is Primary Access Checkbox -->
             <div class="flex items-center mb-4" v-if="$store.getters.isSuperAdmin">
              <input id="is_primary" type="checkbox" v-model="form.is_primary" class="h-4 w-4 text-blue-600 border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700 focus:ring-2 focus:ring-primary-500" />
              <label for="is_primary" class="ml-2 text-sm font-semibold text-gray-700 dark:text-gray-400">Primary Instance Access</label>
            </div>

  
            <!-- Active Checkbox -->
            <div class="flex items-center mb-4">
              <input id="is_active" type="checkbox" v-model="form.is_active" class="h-4 w-4 text-blue-600 border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700 focus:ring-2 focus:ring-primary-500" />
              <label for="is_active" class="ml-2 text-sm font-semibold text-gray-700 dark:text-gray-400">Active</label>
            </div>
  
            <!-- Deactivate At Date Field -->
            <div class="mb-4">
              <label for="deactivate_at" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">Deactivate At</label>
              <input type="date" id="deactivate_at" v-model="form.deactivate_at" class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500" />
              <small class="text-xs text-gray-500 dark:text-gray-400">Optional: Set a future deactivation date.</small>
            </div>
  
            <!-- Submit Button with Loading Spinner -->
            <div class="mt-6">
              <button type="submit" :disabled="isSubmitting" class="w-full !bg-secondary-600 text-white font-semibold py-3 rounded-lg hover:!bg-secondary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center">
                <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
                {{ isSubmitting ? 'Creating...' : 'Create User' }}
              </button>
            </div>
          </form>
        </section>
      </main>
    </div>
  </template>
  

  <script>
  import TopNav from "@/components/headers/TopNav.vue";
  import SideMenu from "@/components/SideMenu.vue";
  import axios from "axios";
  
  export default {
    components: {
      TopNav,
      SideMenu,
    },
    data() {
      return {
        form: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          role: "",
          is_primary: false, // Added is_primary field
          is_active: true,
          deactivate_at: "",
        },
        isSubmitting: false,
        errors: [],
      };
    },
    methods: {
      async createUser() {
        this.isSubmitting = true;
        this.errors = [];
        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;
  
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
              mutation CreateUser($input: UserInput!, $branchUrl: String) {
                CreateUser(input: $input, branchUrl: $branchUrl) {
                  id
                  first_name
                  last_name
                  email
                  role
                  is_primary
                  is_active
                  deactivate_at
                }
              }
            `,
            variables: {
              input: this.form,
              branchUrl,
            },
          });
  
          if (response.data.errors) {
            this.errors = response.data.errors.flatMap((error) =>
              error.extensions.validation
                ? error.extensions.validation['input.email']
                : [error.message]
            );
          } else {
            console.log("User created successfully:", response.data.data.CreateUser);
            this.$router.push({ name: "AdminUsers" });
          }
        } catch (error) {
          this.errors = ["An unexpected error occurred. Please try again later."];
        } finally {
          this.isSubmitting = false;
        }
      },
    },
  };
  </script>
  