import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    user: null,
    branch: null, // Branch data
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },
    clearUser(state) {
      state.user = null;
    },
    setBranch(state, branchData) {
      state.branch = branchData;
    },
    clearBranch(state) {
      state.branch = null;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          commit('setUser', response.data);
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
    },
    async fetchBranch({ commit }, branchUrl) {
      try {
        // Send a GraphQL query to fetch branch data by branchUrl
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query ($branchUrl: String!) {
              branch(url: $branchUrl) {
                name
                url
                logo_url
                primary_color
                secondary_color
              }
            }
          `,
          variables: {
            branchUrl: branchUrl,
          },
        });

        // Check for errors in the GraphQL response
        if (response.data.errors) {
          console.error('GraphQL error:', response.data.errors);
          return;
        }

        // Commit the fetched branch data to the Vuex store
        const branchData = response.data.data.branch;
        commit('setBranch', branchData);

      } catch (error) {
        console.error('Failed to fetch branch:', error);
      }
    },
    clearUser({ commit }) {
      commit('clearUser');
      commit('clearBranch');
    },
  },
  getters: {
    getUser: (state) => state.user,
    isAuthenticated: (state) => !!state.user,
    getBranch: (state) => state.branch,
    getInstanceId: (state) => state.user?.instance_id || null, // Getter for the user's instance ID
    getUserRole: (state) => state.user?.role || null, // Getter for the user's role
    isSuperAdmin: (state) => state.user?.role === 'super_admin', // Check if the user is a super admin
    isAdmin: (state) => state.user?.role === 'admin', // Check if the user is an admin
    isLearner: (state) => state.user?.role === 'learner', // Check if the user is a learner
    isInstructor: (state) => state.user?.role === 'instructor', // Check if the user is an instructor
    isManager: (state) => state.user?.role === 'manager', // Check if the user is an instructor
  },
});

export default store;
