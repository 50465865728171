<template>
  <div class="antialiased bg-white dark:bg-gray-900 min-h-screen">
    <TopNav />
    <SideMenu />

    <main class="p-6 md:ml-64 h-auto pt-20 bg-gray-50">
      <div class="max-w-screen-2xl w-full px-6 mx-auto">
        <div class="mb-6 block">

        <router-link :to="{ name: 'AllCoursesReports' }"
        class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span class="ml-2">Back to course reports</span>
        </router-link>
        </div>

        <!-- Header -->
        <header class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <div>
            <h1 class="text-4xl font-bold text-gray-800 mb-2">{{ course.title || "Course Report" }}</h1>
            <p class="text-gray-500 text-sm">Detailed statistics and performance for this course</p>
          </div>
        </header>

        <!-- Tab Navigation -->
        <div class="border-b border-gray-200 dark:border-gray-700">
          <nav class="flex space-x-6">
            <button v-for="tab in tabs" :key="tab.id" @click="activeTab = tab.id" :class="[
              'py-4 px-6 text-sm font-medium',
              activeTab === tab.id
                ? 'text-primary-700 border-b-2 border-primary-700'
                : 'text-gray-600 hover:text-gray-800',
            ]">
              {{ tab.label }}
            </button>
          </nav>
        </div>

        <!-- Tab Content -->
        <section class="mt-6">

          <div class="grid grid-cols-5 gap-4 mb-6">
            <StatCard title="Completion Rate" :value="stats.completionRate + '%'" icon="completion-rate" />
            <StatCard title="Completed Learners" :value="stats.completedLearners" icon="completed-learners" />
            <StatCard title="In Progress" :value="stats.learnersInProgress" icon="in-progress" />
            <StatCard title="Not Started" :value="stats.learnersNotStarted" icon="not-started" />
            <StatCard title="Total Training Time" :value="stats.trainingTime" icon="training-time" />
          </div>
          <!-- Overview Tab -->

          <div v-if="loading" class="text-center py-10">
            <svg aria-hidden="true"
              class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>


          <div v-if="activeTab === 'overview' && loading == false" class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">Course Overview</h2>


            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- Course Info Card -->
              <div class="bg-gray-50 p-6 rounded-lg shadow-lg">
                <div class="flex flex-col items-center space-y-4">
                  <img :src="courseThumbnail()" alt="Course Thumbnail" class="w-32 h-32 rounded-md object-cover" />
                  <div>
                    <h3 class="text-lg font-semibold text-gray-800 mb-3">Course Information</h3>
                    <ul class="text-sm text-gray-600 space-y-2">
                      <li><strong>Category:</strong> {{ course.category || "N/A" }}</li>
                      <li><strong>Assigned Learners:</strong> {{ stats.assignedLearners }}</li>
                      <li><strong>Created At:</strong> {{ formatDate(course.createdAt) }}</li>
                      <li><strong>Updated At:</strong> {{ formatDate(course.updatedAt) }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- User Progress Pie Chart -->
              <div class="p-6 bg-gray-50 rounded-lg shadow-lg">
                <h3 class="text-lg font-semibold text-gray-800 mb-3">User Progress Distribution</h3>
                <canvas id="userProgressPieChart" class="max-h-96"></canvas>
              </div>

              <!-- Activity Line Graph -->
              <div class="p-6 bg-gray-50 rounded-lg shadow-lg">
                <h3 class="text-lg font-semibold text-gray-800 mb-3">Activity Over Time</h3>
                <canvas id="activityLineGraph" class="max-h-96"></canvas>
              </div>

              <!-- Time Spent Line Graph -->
              <div class="p-6 bg-gray-50 rounded-lg shadow-lg">
                <h3 class="text-lg font-semibold text-gray-800 mb-3">Time Spent (Weekly)</h3>
                <canvas id="timeSpentLineGraph" class="max-h-96"></canvas>
              </div>
            </div>
          </div>

          <!-- Users Tab -->
          <div v-if="activeTab === 'users' && loading == false" class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">Assigned Users</h2>
            <div v-if="users.length > 0" class="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
              <table
                class="min-w-full table-auto border-collapse bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-700">
                <thead>
                  <tr>
                    <th class="px-4 py-3 text-center text-sm font-semibold text-gray-800 dark:text-gray-300">Status</th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Name</th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Role</th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Progress</th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">
                      Assigned Date
                    </th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">
                      Completion Date
                    </th>
                    <th class="px-4 py-3 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Time Spent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id"
                    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    @click="viewUserReport(user.id)">
                    <td class="px-4 py-3 text-center">
                      <span class="inline-block w-4 h-4 rounded-full" :class="statusMarkerClass(user.progress)"></span>
                    </td>
                    <td class="px-4 py-3">{{ user.name }}</td>
                    <td class="px-4 py-3">{{ user.role }}</td>
                    <td class="px-4 py-3">
                      <div class="relative w-full h-4 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                        <!-- Progress Bar -->
                        <div 
                          class="h-full bg-blue-500 dark:bg-blue-400 transition-all duration-300 ease-in-out"
                          :style="{ width: user.progress + '%' }">
                        </div>
                    
                        <!-- Percentage Display -->
                        <div 
                          class="absolute inset-0 flex items-center justify-center text-sm font-medium"
                          :class="user.progress > 20 ? 'text-white' : 'text-gray-800'">
                          {{ user.progress }}%
                        </div>
                      </div>
                    </td>                   
                    
                    <td class="px-4 py-3">{{ formatDate(user.assignedDate) }}</td>
                    <td class="px-4 py-3">{{ formatDate(user.completionDate) }}</td>
                    <td class="px-4 py-3">{{ formatTime(user.timeSpent) }}</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div v-else class="text-center py-10 text-gray-600">No users assigned to this course.</div>
          </div>

          <!-- Timeline Tab -->
          <div v-if="activeTab === 'timeline' && loading == false" class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-800 mb-4">Timeline</h2>
            <p class="text-gray-600">This section will include the course's timeline and activity logs.</p>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import TopNav from "@/components/headers/TopNav.vue";
import SideMenu from "@/components/SideMenu.vue";
import StatCard from "@/components/Reports/StatCard.vue";
import { Chart } from "chart.js/auto";
import axios from "axios";

export default {
  components: { TopNav, SideMenu, StatCard },
  data() {
    return {
      tabs: [
        { id: "overview", label: "Overview" },
        { id: "users", label: "Users" },
      ],
      activeTab: "overview",
      stats: {
        completionRate: 0,
        completedLearners: 0,
        learnersInProgress: 0,
        learnersNotStarted: 0,
        trainingTime: 0,
      },
      course: {},
      users: [],
      chartInstances: {},
      loading: true,
    };
  },
  watch: {
    activeTab(newTab) {
      if (newTab === "overview") {
        this.$nextTick(() => {
          this.initializeCharts();
        });
      }
    },
  },

  methods: {
    viewUserReport(userId) {
      this.$router.push({ name: 'UserReport', params: { id: userId } });
    },
    async fetchCourseReport() {
      const courseId = parseInt(this.$route.params.id, 10);
      try {
        this.loading = true
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query GetCourseReport($courseId: Int!) {
              CourseReport(courseId: $courseId) {
                id
                title
                category
                thumbnail
                createdAt
                updatedAt
                activityOverTime {
                  weekStart
                  weekEnd
                  assignedCount
                  completedCount
                }
                stats {
                  completionRate
                  completedLearners
                  learnersInProgress
                  learnersNotStarted
                  trainingTime
                  assignedLearners
                }
                timeSpentWeekly {
                  weekStart
                  averageTimeSpent
                }
                users {
                  id
                  name
                  role
                  progress
                  assignedDate
                  completionDate
                  timeSpent
                }
              }
            }
          `,
          variables: { courseId },
        });

        const data = response.data.data.CourseReport;
        this.course = data;
        this.stats = data.stats;
        this.users = data.users;
        this.initializeCharts();
      } catch (error) {
        console.error("Error fetching course report:", error);
      } finally {
        this.loading = false
        this.$nextTick(() => {
          this.initializeCharts();
        });
      }
    },
    initializeCharts() {
      const userProgressCanvas = document.getElementById("userProgressPieChart");
      const activityCanvas = document.getElementById("activityLineGraph");
      const timeSpentCanvas = document.getElementById("timeSpentLineGraph");

      // Destroy existing charts if they exist
      if (this.chartInstances.userProgress) {
        this.chartInstances.userProgress.destroy();
      }
      if (this.chartInstances.activity) {
        this.chartInstances.activity.destroy();
      }
      if (this.chartInstances.timeSpent) {
        this.chartInstances.timeSpent.destroy();
      }

      // User Progress Pie Chart
      if (userProgressCanvas) {
        this.chartInstances.userProgress = new Chart(userProgressCanvas, {
          type: "pie",
          data: {
            labels: ["Completed", "In Progress", "Not Started"],
            datasets: [
              {
                data: [
                  this.stats.completedLearners || 0,
                  this.stats.learnersInProgress || 0,
                  this.stats.learnersNotStarted || 0,
                ],
                backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
              },
            ],
          },
        });
      }

      // Activity Line Graph
      if (activityCanvas) {
        const activityLabels = this.course.activityOverTime.map((data) => data.weekStart);
        const assignedData = this.course.activityOverTime.map((data) => data.assignedCount);
        const completedData = this.course.activityOverTime.map((data) => data.completedCount);

        this.chartInstances.activity = new Chart(activityCanvas, {
          type: "line",
          data: {
            labels: activityLabels,
            datasets: [
              {
                label: "Assignments",
                data: assignedData,
                borderColor: "#3B82F6",
                backgroundColor: "rgba(59, 130, 246, 0.2)",
                fill: true,
              },
              {
                label: "Completions",
                data: completedData,
                borderColor: "#10B981",
                backgroundColor: "rgba(16, 185, 129, 0.2)",
                fill: true,
              },
            ],
          },
        });
      }

      // Time Spent Line Graph
      if (timeSpentCanvas) {
        const timeSpentLabels = this.course.timeSpentWeekly.map((data) => data.weekStart);
        const averageTimeSpentData = this.course.timeSpentWeekly.map((data) => data.averageTimeSpent);

        this.chartInstances.timeSpent = new Chart(timeSpentCanvas, {
          type: "line",
          data: {
            labels: timeSpentLabels,
            datasets: [
              {
                label: "Average Time Spent (hh:mm:ss)",
                data: averageTimeSpentData,
                borderColor: "#FF5733",
                backgroundColor: "rgba(255, 87, 51, 0.2)",
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    const value = tooltipItem.raw;
                    return `Average Time: ${this.formatTime(value)}`;
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Week Start",
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: (value) => this.formatTime(value),
                },
                title: {
                  display: true,
                  text: "Average Time",
                },
              },
            },
          },
        });
      }
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString("en-GB") : "N/A";
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
      return `${hrs}h ${mins}m ${secs}s`;
    },
    courseThumbnail() {
      console.log('defo here')
      if (!this.course.thumbnail) {
        return '/img/default-course-image.jpg'; // Fallback to default image
      }

      console.log('WE here?')


      // Check if the thumbnail is already an absolute URL
      if (this.course.thumbnail.startsWith('http://') || this.course.thumbnail.startsWith('https://')) {
        return this.course.thumbnail;
      }

      // Prepend the base API URL for relative paths
      return `${process.env.VUE_APP_API_URL}${this.course.thumbnail.startsWith('/') ? '' : '/'}${this.course.thumbnail}`;
    },
    statusMarkerClass(completionPercentage) {
      if (completionPercentage === 0) return "bg-gray-400";
      if (completionPercentage < 100) return "bg-yellow-500";
      return "bg-green-500";
    },
  },
  mounted() {
    this.fetchCourseReport();
  },
};
</script>

<style scoped>
.main {
  background-color: #f8f9fa;
}
</style>